import $ from 'jquery';
import { gsap } from 'gsap/all';
import locomotiveInit from './locomotive.js';

const offers = () => {
    let offersTl = gsap.timeline();

    new SplitText(".title, .lines-other", {
        type: "lines"
    });

    let offerFirstItem = $(".offer-item").eq(0),
        offerFirstItemTitle = offerFirstItem.find("h2"),
        offerFirstItemText = offerFirstItem.find("p");

    offersTl.set(".title", {
        className: "+= title outline text-center title-show"	
    }).set(".title", {
        className: "+= title outline text-center title-show title-effect"	
    }, 0.4).set(".book", {
		className: "+= book book-init"
	}, 0.75).set(".book-inner", {
		className: "+= book-inner book-inner-show"
	}, 1.35).set(".logo", {
		className: "+= logo logo-show"
	}, 0.85).set(".sidebar", {
		className: "+= sidebar sidebar-show"
	}, 0.9).set(".menubar", {
		className: "+= menubar menubar-show"
    }, 0.95);
    
    if (offerFirstItemTitle) {
        offersTl.set(offerFirstItemTitle, {
            className: "+= text-effect visible"
        }, 0.7);
    }

    if (offerFirstItemText) {
        offersTl.set(offerFirstItemText, {
            className: "+= text-effect visible"
        }, 0.85);
    }

    $(window).on("load", function() {
        locomotiveInit();

        offersTl.play();
    });
};

export default offers;