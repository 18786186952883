import $ from 'jquery';
import about from './about.js';
import offer from './offer.js';
import offers from './offers.js';
import room from './room.js';
import restaurant from './restaurant.js';
import home from './home.js';
import careers from './careers.js';
import privacy from './privacy.js';
require("../__vendor/SplitText.min.js");

const loader = () => {
	if($(".slider").length) {
		home();
	} else if ($(".about-main").length) {
		about();
	} else if ($(".content-offer").length) {
		offer();
	} else if ($(".content-offers").length) {
		offers();
	} else if ($(".content-room").length) {
		room();
	} else if ($(".content-restaurant").length) {
		restaurant();
	} else if ($(".content-offers").length) {
		careers();
	} else if ($(".content-privacy").length) {
		privacy();
	}
};

export default loader;