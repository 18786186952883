import $ from 'jquery';
import { gsap } from 'gsap/all';
import locomotiveInit from './locomotive.js';

const about = () => {
	let aboutTl = gsap.timeline();

	if($(window).width() > 1200) {
		let aboutLocationText = $(".about-location-text"),
			aboutLocationTextFirst = aboutLocationText.find("strong").width(),
			aboutLocationTextSecond = aboutLocationText.find("span").width();

		if(aboutLocationTextSecond > aboutLocationTextFirst) {
			$(".about-location-text span").css("transform", "translate(-" + (aboutLocationTextSecond - aboutLocationTextFirst) +  "px)");
		}
	}
	
	new SplitText(".about-main h2, .lines-other", {
		type: "lines"
	});

	aboutTl.set(".about-main", {
		className: "+= about-main about-main-show"
	}).set(".about-main h2", {
		className: "+= lines-show"	
	}).set(".about-main h2", {
		className: "+= lines-show lines-effect"	
	}, 0.4);

	aboutTl.set(".book", {
		className: "+= book book-init"
	}, 0.75).set(".book-inner", {
		className: "+= book-inner book-inner-show"
	}, 1.35).set(".logo", {
		className: "+= logo logo-show"
	}, 0.85).set(".sidebar", {
		className: "+= sidebar sidebar-show"
	}, 0.9).set(".menubar", {
		className: "+= menubar menubar-show"
	}, 0.95);

	$(window).on("load", function() {
		locomotiveInit();

		aboutTl.play();
	});
};

export default about;