import $ from 'jquery';
import { gsap, Power4 } from 'gsap/all';

const search = () => {
	let searchSection = $(".search"),
		searchForm = searchSection.find("form"),
		searchTl = gsap.timeline({
			paused: true
		});

	// Search Timeline
	searchTl.set(searchSection, {
		className: "+= search search-show"
	}).to(searchSection, 0.6, {
		x: 0,
		ease: Power4.ease
	}).fromTo(searchForm, 0.425, {
		autoAlpha: 0
	}, {
		autoAlpha: 1,
		ease: Power4.ease,
		onComplete: function () {
			// Use a setTimeout to delay the focus call slightly
			setTimeout(function () {
				searchForm.find("input").focus();
			}, 100);
		}
	}, 0.545);

	// Search show and hide
	$(".sidebar-inner .icon-search").on("click", (e) => {
		e.preventDefault();

		if (searchSection.hasClass("search-show")) {
			searchTl.timeScale(2).reverse();
			searchForm.find("input").val("");
		} else {
			searchTl.timeScale(1).play();
		}
	});

	$(document).keydown(function (event) {
		if (event.key === "Escape") {
			searchTl.timeScale(2).reverse();
			searchForm.find("input").val("");
		}
	});
};

export default search;
