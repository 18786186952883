import $ from 'jquery';
import { gsap, Power4 } from 'gsap/all';

require("../__vendor/datepicker");

const book = () => {
	let bookSectionTl = gsap.timeline({
		paused: true
	});

	bookSectionTl.set(".book-section", {
		className: "+= book-section book-section-show"
	}).to(".book-section", 0.8, {
		x: 0,
		ease: Power4.ease
	}).staggerFromTo(".book-section .form-control, .book-section button", 0.5, {
		autoAlpha: 0
	}, {
		autoAlpha: 1,
		ease: Power4.ease
	}, 0.025, 0.7);

	//Book - show and hide
	$(".book-inner").on("click", (e) => {
		e.preventDefault();

		$(".book-inner").toggleClass("book-inner-active");

		if($(".book-section").hasClass("book-section-show")) {
			bookSectionTl.timeScale(3).reverse();
		} else {
			bookSectionTl.timeScale(1).play();
		}
	});

	// Make active background on room selection
    $(".book-section-inner select").on("change", function(e) {
        e.preventDefault();

		let selectedOption = $(this).find('option:selected'),
			selectedOptionRoom = selectedOption.attr("data-room");
		
		$("." + selectedOptionRoom).addClass("active");
    });

	// Add data attribute to options
	$.each($(".book-section-inner select option").slice(1), function() {
		let itemText = $(this).text(),
			modifiedText = itemText.toLowerCase().replace(/\s+/g, '-') + "-room";

		$(this).attr("data-room", modifiedText);
	});

    // Datepicker
	let dateFormat = "mm/dd/yy",
		from = $( "#checkin" ).datepicker({
        	defaultDate: "+1w",
        	changeMonth: true,
        	numberOfMonths: 1
        }).on( "change", function() {
        	to.datepicker( "option", "minDate", getDate( this ) );
        }),
		to = $( "#checkout" ).datepicker({
			defaultDate: "+1w",
			changeMonth: true,
			numberOfMonths: 1
		}).on( "change", function() {
			from.datepicker( "option", "maxDate", getDate( this ) );
		});
    
	// Get Date function
    function getDate( element ) {
		var date;
		try {
			date = $.datepicker.parseDate( dateFormat, element.value );
		} catch( error ) {
			date = null;
		}
	
		return date;
    }
};

export default book;