require("../__vendor/bxSlider.js");

const slider = () => {
	let slider = $(".slider");

	// Slider Init
	slider.bxSlider({
		mode: 'fade',
		pager: false,
		controls: false,
		speed: 600,
		auto: true,
		pause: 8000,
		easing: 'cubic-bezier(0,.7,.3,1)'
	});

	// Slider arrow click
	$(".slider-arrow").on("click", (e) => {
		e.preventDefault();

		$(e.target).index() === 0 ? slider.goToPrevSlide() : slider.goToNextSlide();
	});
};

export default slider;