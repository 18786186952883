import $ from 'jquery';
import LocomotiveScroll from 'locomotive-scroll';

const locomotiveInit = () => {
    const scroll = new LocomotiveScroll({
        el: document.querySelector('[data-scroll-container]'),
        smooth: true,
        direction: 'vertical',
        offset: "30%",
        getSpeed: true,
        class: 'visible',
        reverse: false
    });

    $(".career-item h4").on("click", function (e) {
        setTimeout(function() {
            scroll.update();
        }, 500);
    });
};

export default locomotiveInit;