import $ from 'jquery';
import { gsap } from 'gsap/all';
import locomotiveInit from './locomotive.js';

const offer = () => {
    $(".offer-text p").addClass("text-effect").attr("data-scroll", true);

    let offerTl = gsap.timeline();

    new SplitText(".title, .lines-other", {
		type: "lines"
	});

    offerTl.set(".title", {
        className: "+= title outline text-center title-show"	
    }).set(".title", {
        className: "+= title outline text-center title-show title-effect"	
    }, 0.4);

    offerTl.set(".book", {
		className: "+= book book-init"
	}, 0.75).set(".book-inner", {
		className: "+= book-inner book-inner-show"
	}, 1.35).set(".logo", {
		className: "+= logo logo-show"
	}, 0.85).set(".sidebar", {
		className: "+= sidebar sidebar-show"
	}, 0.9).set(".menubar", {
		className: "+= menubar menubar-show"
	}, 0.95);

    $(window).on("load", function() {
        locomotiveInit();

        offerTl.play();
    });
};

export default offer;