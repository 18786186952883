import $ from 'jquery';
import { gsap } from 'gsap/all';
import locomotiveInit from './locomotive.js';

const careers = () => {
    let careersTl = gsap.timeline();

    new SplitText(".title, .lines-other", {
        type: "lines"
    });

    careersTl.set(".title", {
        className: "+= title outline text-center title-show"
    }).set(".title", {
        className: "+= title outline text-center title-show title-effect"
    }, 0.4).set(".book", {
        className: "+= book book-init"
    }, 0.75).set(".book-inner", {
        className: "+= book-inner book-inner-show"
    }, 1.35).set(".logo", {
        className: "+= logo logo-show"
    }, 0.85).set(".sidebar", {
        className: "+= sidebar sidebar-show"
    }, 0.9).set(".menubar", {
        className: "+= menubar menubar-show"
    }, 0.95);

    $(window).on("load", function () {
        locomotiveInit();

        careersTl.play();
    });
    
    $(".career-item h4").on("click", function(e) {
        e.preventDefault();

        $(this).toggleClass("active").next().slideToggle();
    });

};

export default careers;