import $ from 'jquery';
import { gsap } from 'gsap/all';
import locomotiveInit from './locomotive.js';

const privacy = () => {
    let privacyTl = gsap.timeline();

    new SplitText(".title, .lines-other", {
        type: "lines"
    });

    privacyTl.set(".title", {
        className: "+= title outline text-center title-show"
    }).set(".title", {
        className: "+= title outline text-center title-show title-effect"
    }, 0.4);

    privacyTl.set(".book", {
        className: "+= book book-init"
    }, 0.75).set(".book-inner", {
        className: "+= book-inner book-inner-show"
    }, 1.35).set(".logo", {
        className: "+= logo logo-show"
    }, 0.85).set(".sidebar", {
        className: "+= sidebar sidebar-show"
    }, 0.9).set(".menubar", {
        className: "+= menubar menubar-show"
    }, 0.95);

    $(window).on("load", function () {
        locomotiveInit();

        privacyTl.play();
    });
};

export default privacy;