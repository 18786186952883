import $ from 'jquery';
import { gsap } from 'gsap/all';
import locomotiveInit from './locomotive';

const restaurant = () => {
    let roomTl = gsap.timeline(),
        roomSlider = $(".room-slider");

    new SplitText(".title, .lines-other, .room-main-inner h2", {
		type: "lines"
	});

    roomTl.set(".room-main-inner h2", {
        className: "+= lines-show"
    }).set(".title", {
        className: "+= title outline text-center title-show title-effect"	
    }, 0.4).set(".room-main-inner h2", {
        className: "+= lines-show lines-effect"
    }, 0.4).set(".room-slider", {
        autoAlpha: 1
    }, 0.5);

    roomTl.set(".room-main-inner", {
        className: "+= room-main-inner room-main-inner--show"
    });

    roomTl.set(".book", {
		className: "+= book book-init"
	}, 0.75).set(".book-inner", {
		className: "+= book-inner book-inner-show"
	}, 1.35).set(".logo", {
		className: "+= logo logo-show"
	}, 0.85).set(".sidebar", {
		className: "+= sidebar sidebar-show"
	}, 0.9).set(".menubar", {
		className: "+= menubar menubar-show"
	}, 0.95).set(".slider-arrows", {
        className: "+= slider-arrows slider-arrows-show"
    }, 1);

    $(window).on("load", function() {
        locomotiveInit();

        roomSlider.bxSlider({
            pager: false,
            controls: false,
            speed: 1000,
            auto: true,
            pause: 8000,
            easing: 'ease'
        });

        roomTl.play();
    });

    $(".slider-arrow").on("click", (e) => {
        e.preventDefault();

        let sliderArrowIndex = $(e.target).index();

        if(sliderArrowIndex === 0) {
            roomSlider.goToPrevSlide();
        } else {
            roomSlider.goToNextSlide();
        }
    });

    if($(".room-hightlight-slider").length) {
        let roomHighlightSlider = $(".room-hightlight-slider");

        roomHighlightSlider.bxSlider({
            pager: true,
            controls: false,
            speed: 1000,
            auto: true,
            pause: 8000,
            easing: 'ease'
        });
    }
};

export default restaurant;