import $ from 'jquery';
import { gsap, Power4 } from 'gsap/all';

require("../__vendor/datepicker");

const bookStay = () => {
    let bookSectionTl = gsap.timeline({
        paused: true
    });

    bookSectionTl.set(".book-section", {
        className: "+= book-section book-section-show"
    }).to(".book-section", 0.6, {
        x: 0,
        ease: Power4.ease
    }).staggerFromTo(".book-section .form-control, .book-section button", 0.425, {
        autoAlpha: 0,
        y: 20
    }, {
        autoAlpha: 1,
        y: 0,
        ease: Power4.ease
    }, 0.05, 0.545);

    //Book - show and hide
    $(".book-stay a").on("click", (e) => {
        e.preventDefault();

        $(".book-inner").toggleClass("book-inner-active");

        if ($(".book-section").hasClass("book-section-show")) {
            bookSectionTl.timeScale(3).reverse();
        } else {
            bookSectionTl.timeScale(1).play();
        }
    });
};

export default bookStay;