import $ from 'jquery';
import { gsap, Power4 } from 'gsap/all';

const menu = () => {
	let menuSection = $(".menu");
	let menuBar = $(".menubar");
	let menuTl = gsap.timeline({
		paused: true
	});

	// Menu effect
	menuTl
		.set(menuSection, {
			className: "+= menu menu-show"
		})
		.to(menuSection, 0.6, {
			x: 0,
			ease: Power4.ease
		})
		.staggerFromTo(".menu-inner li", 0.5, {
			autoAlpha: 0,
			x: 20
		}, {
			autoAlpha: 1,
			x: 0,
			ease: Power4.ease
		}, 0.05, 0.545);

	// Menubar click
	menuBar.on("click", (e) => {
		e.preventDefault();

		if (menuSection.hasClass("menu-show")) {
			menuTl.timeScale(2).reverse();
		} else {
			menuTl.timeScale(1).play();
		}
	});

	// Menu inner items hover
	if($(window).width() > 991) {
		$(".menu-inner li a").hover(
			function () {
				let itemIndex = $(this).parent().index();
				$(".menu-bg > div").removeClass("show"); // Reset all backgrounds
				$(".menu-bg > div").eq(itemIndex).addClass("show");
			},
			function () {
				// Handle mouseleave if needed
				$(".menu-bg > div").removeClass("show");
			}
		);
	}
};

export default menu;