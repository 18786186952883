import $ from 'jquery';
import { gsap } from 'gsap/all';
import locomotiveInit from './locomotive';

const room = () => {

    let roomTl = gsap.timeline(),
        roomSlider = $(".room-slider");

    new SplitText(".title, .lines-other, .room-main-inner h2", {
		type: "lines"
	});

    if($(".title").length) {
        roomTl.set(".title", {
            className: "+= title outline text-center title-show"
        }).set(".title", {
            className: "+= title outline text-center title-show title-effect"
        }, 0.4);
    }

    if ($(".room-main-inner").length) {
        roomTl.set(".room-main-inner h2", {
            className: "+= lines-show"
        }).set(".room-main-inner h2", {
            className: "+= lines-show lines-effect"
        }, 0.4);
    }

    if($(".room-slider").length) {
        roomTl.set(".room-slider", {
            autoAlpha: 1
        }, 0.5);
    }

    if ($(".room-main-inner").length) {
        roomTl.set(".room-main-inner", {
            className: "+= room-main-inner room-main-inner--show"
        });
    }

    roomTl.set(".book", {
		className: "+= book book-init"
	}, 0.75).set(".book-inner", {
		className: "+= book-inner book-inner-show"
	}, 1.35).set(".logo", {
		className: "+= logo logo-show"
	}, 0.85).set(".sidebar", {
		className: "+= sidebar sidebar-show"
	}, 0.9).set(".menubar", {
		className: "+= menubar menubar-show"
	}, 0.95)
    
    if($(".slider-arrows").length) {
        roomTl.set(".slider-arrows", {
            className: "+= slider-arrows slider-arrows-show"
        }, 1);
    }

    $(window).on("load", function() {
        locomotiveInit();

        roomSlider.bxSlider({
            pager: false,
            controls: false,
            speed: 1000,
            auto: true,
            pause: 8000,
            //easing: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
            easing: 'ease'
        });

        roomTl.play();
    });

    $(".slider-arrow").on("click", (e) => {
        e.preventDefault();

        let sliderArrowIndex = $(e.target).index();

        if(sliderArrowIndex === 0) {
            roomSlider.goToPrevSlide();
        } else {
            roomSlider.goToNextSlide();
        }
    });

    if($(".room-hightlight-slider").length) {
        let roomHighlightSlider = $(".room-hightlight-slider");

        roomHighlightSlider.bxSlider({
            pager: true,
            controls: false,
            speed: 1000,
            auto: true,
            pause: 8000,
            //easing: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
            easing: 'ease'
        });
    }
};

export default room;