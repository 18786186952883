import $ from 'jquery';
import { gsap } from 'gsap/all';
import locomotiveInit from './locomotive.js';

const home = () => {
    let loaderTl = gsap.timeline();

    new SplitText(".slider h2", {
        type: "lines"
    });

    loaderTl.set(".slider", {
        className: "+= slider slider-init"
    }).set(".slider h2", {
        className: "+= lines-show"	
    }).set(".slider h2", {
        className: "+= lines-show lines-effect"	
    }, 0.4).set(".slider-arrows", {
        className: "+= slider-arrows slider-arrows-show"
    }, 1);

    loaderTl.set(".book", {
        className: "+= book book-init"
    }, 1.35).set(".book-inner", {
        className: "+= book-inner book-inner-show"
    }, 1.35).set(".logo", {
        className: "+= logo logo-show"
    }, 0.85).set(".sidebar", {
        className: "+= sidebar sidebar-show"
    }, 0.9).set(".menubar", {
        className: "+= menubar menubar-show"
    }, 0.95);

    $(window).on("load", function() {
        locomotiveInit();

        loaderTl.play();
    });
};

export default home;