import "@babel/polyfill";
import $ from 'jquery';
import slider from "./__modules/slider";
import menu from "./__modules/menu";
import book from "./__modules/book";
import search from "./__modules/search";
import loader from "./__modules/loader";
import bookStay from "./__modules/bookStay";
import careers from "./__modules/careers";

document.addEventListener('DOMContentLoaded', () => {

	loader();

	// Slider Init
	if($(".slider").length) {
		slider();
	}

	// Menu overlay
	if($(".menu").length) {
		menu();
	}

	// Search overlay
	if($(".search").length) {
		search();
	}

	// Book section overlay
	if($(".book-section").length) {
		book();
	}

	// Book stay overlay
	if($(".book-stay").length) {
		bookStay();
	}

	if($(".career-item").length) {
		careers();
	}
});

window.addEventListener('load', () => {
});

window.addEventListener('resize', () => {
});

window.addEventListener('scroll', () => {
});